import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { FaTag } from "react-icons/fa"

const TagsTemplate = ({ data }) => {
  const groups = data.allMarkdownRemark.group

  return (
    <Layout className="page">
      <Seo />
      <div className="wrapper">
        <h1>Tags</h1>
          {groups.map(group => (
            <div key={group.fieldValue}>
              <h2><FaTag /> &nbsp; {group.fieldValue}</h2>
              <ul>
                {group.edges.map(edge => (
                  <li key={edge.node.id}>
                    <h3>
                    &nbsp;
                      <a href={edge.node.frontmatter.slug}>
                        {edge.node.frontmatter.title}
                      </a>
                    </h3>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      group(field: frontmatter___tags) {
        fieldValue
        edges {
          node {
            id
            frontmatter {
              slug
              template
              title
            }
          }
        }
      }
    }
  }
`

export default TagsTemplate
